import Accordions from "../container/Accordions";
import Sign from "./Sign";
import MainLayout from "./MainLayout";
import TwoMainLines from "./TwoMainLines";
import Footer from "./Footer";
import BusinessInfo from "./BusinessInfo";
import JobAdvertisement from "./JobAdvertisement";

const App = () => (
    <div>
        <MainLayout>
            <Sign/>
            <JobAdvertisement />
            <BusinessInfo className="mt-8 sm:mt-12" />
            <Accordions className="mt-10 sm:mt-16"/>
            <TwoMainLines
                firstLine="Unser familiär geführtes Unternehmen existiert vor Ort seit 1987."
                secondLine="Nicht ohne Stolz weisen wir darauf hin, während dieser langen Zeitspanne stets auch auf individuelle Kundenwünsche zuverlässig eingegangen zu sein."
            />
            <Footer className="mt-16 sm:mt-20 mb-5 sm:mb-0" />
        </MainLayout>
    </div>
);

export default App;
