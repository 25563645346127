import React from "react";
import {Accordion, AccordionTab} from 'primereact/accordion';
import TelephoneNumber from "../presentational/TelephoneNumber";
import Email from "../presentational/Email";

interface IProps {
    className?: string
}

interface IState {
    activeIndex: number | null
}

class Accordions extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            activeIndex: 0
        };

        this.handleTabChange = this.handleTabChange.bind(this);
    }

    handleTabChange(e: any) {
        this.setState({
            activeIndex: e.index
        })
    }

    render() {
        return (
            <Accordion
                className={this.props.className}
                activeIndex={this.state.activeIndex}
                onTabChange={this.handleTabChange}
                multiple
            >
                <AccordionTab header="Einzelheiten der Durchführung">
                    Sie bestellen telefonisch unter
                    <TelephoneNumber number="+491713841696" numberDisplay="0171/3841696"/>
                    bzw.
                    <TelephoneNumber number="+49217380795" numberDisplay="02173/80795"/><div className="inline sm:hidden"><br /></div>
                    oder per E-Mail unter<div className="inline sm:hidden"><br /></div>
                    <Email email="info@bartel-schmitz.de"/>
                    für den nächsten Tag oder einen späteren Tag und geben dabei Ihre Rückrufnummer an, falls Sie uns persönlich nicht erreichen konnten.
                </AccordionTab>
                <AccordionTab header="Lieferkosten">
                    Wir schlagen auf unsere marktüblichen Abholpreise eine Liefergebühr auf, die sich nach dem Ort und den Umständen der
                    Belieferung richtet und teilen Ihnen diese telefonisch oder per E-Mail vorab mit.
                </AccordionTab>
                <AccordionTab header="Sortiment">
                    Neben den gängigen, im Markt standardmäßig verfügbaren Artikeln (nicht nur Getränke) erfüllen wir gerne auch Ihre darüber hinausgehenden
                    Markenwünsche, soweit uns das möglich ist.<br />
                    Das klären wir gerne gemeinsam mit Ihnen ab (Rückrufnummer!) <br />
                    (kalte Getränke und Partyequipment liefern wir auch)
                </AccordionTab>
                <AccordionTab header="Auslieferungsfahrer">
                    Unsere freundlichen und zuverlässigen Zusteller sind allesamt länger bei uns beschäftigt und werden Ihnen schnell vertraut sein, sodass Sie bald
                    wissen, wen Sie ins Haus lassen.
                </AccordionTab>
            </Accordion>
        );
    }
}

export default Accordions;
