interface IProps {
    children: any
}

const MainLayout = (props: IProps) => (
    <div className="h-full pt-4 sm:pt-0 xl:py-10 flex justify-center bg-gradient-to-b from-white to-blue-light">
        <div className="relative max-w-7xl bg-white rounded-2xl my-auto p-0 sm:p-6 md:p-8 lg:p-12 lg:pb-4 shadow-2xl">
            <div className="max-w-5xl mx-auto">
                {props.children}
            </div>
        </div>
    </div>
);

export default MainLayout;
