import TelephoneNumber from "./TelephoneNumber";
import {useEffect} from "react";
import {Messages} from "primereact/messages";

interface IProps {
    className?: string
}

const JobAdvertisement = (props: IProps) => {

    let msgs: any = null;

    useEffect(() => {
        msgs.show({
            severity: 'info', sticky: true, content: (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                    </svg>
                    <div className="ml-4 sm:ml-6 text-base">
                        Ab sofort suchen wir 1-2 Mitarbeiter für einen <strong>Minijob/Teilzeitjob</strong>.<br/>
                        Interessenten bitte telefonisch melden unter <TelephoneNumber number="01713841696" numberDisplay="0171/3841696" />
                    </div>
                </>
            )
        });
    });

    return (
        <div className={` ${props.className}`}>
            <Messages ref={(el) => msgs = el}/>
        </div>
    );
};

export default JobAdvertisement;
