import Link from "./Link";

interface IProps {
    number: string,
    numberDisplay: string
}

const TelephoneNumber = (props: IProps) => (
    <Link href={`tel:${props.number}`}>
        <strong>{props.numberDisplay}</strong>
    </Link>
);

export default TelephoneNumber;
