interface IProps {
    firstLine: string,
    secondLine: string
}

const TwoMainLines = (props: IProps) => (
    <div className="mx-auto pt-12 lg:pt-16 px-2 sm:px-0">
        <h2 className="text-md sm:text-3xl text-left sm:text-justify font-extrabold tracking-tight ">
            <span className="block text-red">{props.firstLine}</span>
            <span className="block text-blue mt-3">{props.secondLine}</span>
        </h2>
    </div>
);

export default TwoMainLines;
