import Link from "./Link";

interface IProps {
    email: string,
}

const Email = (props: IProps) => (
    <Link href={`mailto:${props.email}`}>
        <strong>{props.email}</strong>
    </Link>
);

export default Email;
