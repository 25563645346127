import {Card} from "primereact/card";
import TelephoneNumber from "./TelephoneNumber";
import Email from "./Email";

interface IProps {
    className?: string
}

const BusinessInfo = (props: IProps) => (
    <div className={`grid grid-cols-1 lg:grid-cols-2 gap-y-5 lg:gap-x-3 ${props.className}`}>
        <Card title="Kontakt" className="col-span-1">
            <p>
                Telefon: <TelephoneNumber number="+491713841696" numberDisplay="0171 3841696"/>
            </p>
            <p className="mt-3">
                E-Mail: <Email email="info@bartel-schmitz.de"/>
            </p>
        </Card>
        <Card title="Adresse" className="col-span-1">
            <p className="mb-3 md:mb-5">
                Düsseldorfer Straße 49<br/>
                40764 Langenfeld
            </p>
            <a href="https://www.google.de/maps/place/Getr%C3%A4nke+Bartel+%26+Schmitz/@51.1053592,6.9413406,17z/data=!3m1!4b1!4m5!3m4!1s0x47bf2d7a3a2139ab:0xcbb67e1ecc7cff33!8m2!3d51.1053592!4d6.9435293"
               target="_blank"
               rel="noreferrer noopener"
               className="px-3 py-1 border border-transparent text-base font-medium rounded-md text-white bg-blue hover:bg-blue-light md:py-2 md:px-4 mt-3">
                Google Maps
            </a>
        </Card>
        <Card title="Öffnungszeiten" className="grid-cols-1 lg:col-span-2">
            <div className="grid grid-cols-3 gap-x-0 w-60">
                <p className="col-span-1">
                    Mo - Fr:
                </p>
                <p className="col-span-2">
                    14:00 - 21:00 Uhr
                </p>
                <p className="col-span-1 mt-3">
                    Sa - So:
                </p>
                <p className="col-span-2 mt-3">
                    10:00 - 21:00 Uhr
                </p>
                <p className="col-span-1 mt-3">
                    Feiertage:
                </p>
                <p className="col-span-2 mt-3">
                    10:00 - 21:00 Uhr
                </p>
            </div>
        </Card>
    </div>
);

export default BusinessInfo;
