import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import PrimeReact from 'primereact/api';
import { locale, addLocale } from 'primereact/api';
import App from './presentational/App';


PrimeReact.ripple = true;

addLocale('de', {
    accept: 'Ja',
    reject: 'Nein',
    choose: 'Auswählen',
    upload: 'Hochladen',
    cancel: 'Abbruch',
    dayNames: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
    dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
    dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    monthNamesShort: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    today: 'Heute',
    clear: 'Leeren',
    weekHeader: 'Wo',
    firstDayOfWeek: 1,
    dateFormat: 'dd.mm.yy',
    weak: 'Woche',
    medium: 'Mittel',
    strong: 'Stark',
    passwordPrompt: 'Passwort eingeben',
});
locale('de');


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
