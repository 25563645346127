import Link from "./Link";

interface IProps {
    className?: string,
    children?: any
}

const Footer = (props: IProps) => (
    <div className={`w-80 grid grid-cols-2 gap-x-1 mx-auto ${props.className}`}>
        <Link href="impressum.html" className="col-span-1 text-sm text-center">
            Impressum
        </Link>
        <Link href="datenschutz.html" className="col-span-1 text-sm text-center">
            Datenschutzerklärung
        </Link>
    </div>
);

export default Footer;
