import signImage from '../assets/images/schild1080.png'

interface IProps {
    className?: string
}

const Sign = (props: IProps) => (
    <img
        className={props.className}
        src={signImage}
        alt="Logo von Getränke Bartel & Schmitz"
    />
);

export default Sign;
