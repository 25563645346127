interface IProps {
    href: string,
    className?: string,
    children: any
}

const Link = (props: IProps) => (
    <a className={`text-blue-light px-1 ${props.className}`}
       href={props.href}
    >
        {props.children}
    </a>
);

export default Link;
